// import store from "@/store";
import _ from 'lodash';
import { find, filter, orderBy, uniq } from 'lodash/fp';
import * as Sentry from '@sentry/browser';
import store from '@/store';

const colors = ['bg-teal-400'];
const catColors = ['teal', 'blue', 'pink'];
const foodTypes = [
  { key: 'wet_pouch', display: 'Pouches' },
  { key: 'wet_tray', display: 'Trays' },
  { key: 'wet_can', display: 'Cans' },
  { key: 'wet_smooth', display: 'Smooth' },
  { key: 'dry', display: 'Dry Food' },
  { key: 'wet_tasty', display: 'Tasty Slices' },
  { key: 'wet_chunk', display: 'Tasty Chunks' }
];
function getMpId(userId) {
  let cookies = document.cookie.split(';');
  let mpId = '';
  if (userId) {
    mpId = userId;
  } else {
    const regex = /(mp_\S+_mixpanel)/g;
    _.forEach(cookies, function (value) {
      let cookie = value.split('=');

      if (cookie[0].match(regex)) {
        let val = JSON.parse(decodeURIComponent(cookie[1]));
        mpId = val['distinct_id'];
      }
    });
  }
  if (process.env.NODE_ENV === 'production') {
    Sentry.configureScope(function (scope) {
      scope.setUser({ id: mpId });
    });
  }

  return mpId;
}

function stringHasher(string) {
  let h;
  for (let i = 0; i < string.length; i++)
    h = (Math.imul(31, h) + string.charCodeAt(i)) | 0;

  return h;
}

function setStorage(key, value, type) {
  let encoded = btoa(unescape(encodeURIComponent(JSON.stringify(value))));

  if (type === 'local') {
    localStorage.setItem(key, encoded);
  } else {
    sessionStorage.setItem(key, encoded);
  }
}

function getStorage(key, type) {
  let value;
  if (type === 'local') {
    value = localStorage.getItem(key);
  } else {
    value = sessionStorage.getItem(key);
  }

  let decoded;

  if (checkBase64(value)) {
    decoded = JSON.parse(decodeURIComponent(escape(atob(value))));
  } else {
    decoded = [];
  }
  return decoded;
}
function checkBase64(value) {
  try {
    JSON.parse(atob(value));
    return true;
  } catch {
    return false;
  }
}
function colorChanger() {
  return _.shuffle(colors);
}

function getCatColor(index, value) {
  let v = value ? value : 400;
  let color = {
    border: 'border-' + catColors[index] + '-' + v,
    text: 'text-' + catColors[index] + '-' + v,
    background: 'bg-' + catColors[index] + '-' + v
  };
  return color;
}

function skuToProduct(sku) {
  const products =
    store.state.products.products || getStorage('products', 'local');
  return find(['sku', sku], products);
}

function formatFoodTypeName(type) {
  let foundType = find(['key', type], foodTypes);
  return foundType.display;
}

function productByType(type) {
  return orderBy(
    ['popular'],
    ['desc'],
    filter(
      ['foodtype', type],
      filter('available', store.state.products.products)
    )
  );
}

function noOfProducts(amount, increment) {
  return amount / increment;
}

function foodSuggestor(cat) {
  const food = [];

  let wetAmount = cat.foodAmount;

  let wetProducts = productByType('wet_pouch');
  wetProducts = filter(function (o) {
    return cat.isKitten ? o.isKitten : !o.isKitten;
  }, wetProducts);

  let amounts = {
    wet: noOfProducts(wetAmount, 5)
  };

  let overflow = 0;

  for (let i = 0; i < amounts.wet; i++) {
    if (i < wetProducts.length) {
      let product = {
        sku: wetProducts[i].sku,
        quantity: 1
      };

      food.push(product);
    } else {
      food[overflow].quantity++;
      if (overflow == wetProducts.length - 1) {
        overflow = 0;
      } else {
        overflow++;
      }
    }
  }

  return food;
}

function getProductPrice(sku) {
  const product = skuToProduct(sku);
  return (product.exvat / 100).toFixed(2);
}

function getSubscriptionItems(cats) {
  const items = [];

  cats.forEach((c) => {
    c.products.forEach((p) => {
      const isFound = find(['id', p.sku], items);

      if (isFound) {
        isFound.quantity += p.quantity;
      } else {
        items.push({
          id: p.sku,
          name: p.sku,
          price: getProductPrice(p.sku),
          quantity: p.quantity
        });
      }
    });
  });
  return items;
}

function setUrlParameter(key, value) {
  var queryParams = new URLSearchParams(window.location.search);
  queryParams.set(key, value);

  history.replaceState(null, null, '?' + queryParams.toString());
}

function calcDiscount(amount) {
  const discount = (amount * (1 - store.state.discountAmount.cost)) / 100;

  return discount;
}

function encodeString(string) {
  return btoa(unescape(encodeURIComponent(string)));
}
function decodeString(string) {
  return decodeURIComponent(escape(atob(string)));
}

function setAdID(adID) {
  let ids = getStorage('adid', 'local') || [];

  ids = ids.filter((id) => id !== adID);

  ids.push(adID);
  setStorage('adid', ids, 'local');
}

function sendAdEvent(ad) {
  store.dispatch('sendMpEvent', {
    event: 'From advert',
    properties: {
      adID: ad,
      type: 'EVT'
    }
  });
}

function getPercentageOf(total, val) {
  const value = (val / total) * 100;
  return isNaN(value) ? 0 : value;
}
function getPercentageDiff(total, val) {
  var decreaseValue = total - val;

  const value = (decreaseValue / total) * 100;

  return value;
}

function getCookie(name) {
  var cookieArr = window.document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

function isNewProduct(sku) {
  const newSkus = [];
  return newSkus.includes(sku);
}

function getDisplayCost(cost, minus, display) {
  if (display) {
    const pounds = (cost - minus) / 100;
    return `£${pounds.toLocaleString(undefined, {
      minmumSignificantDigits: 2
    })}`;
  } else {
    return cost - minus;
  }
}

function formatCoupons(payload) {
  const coupons = {};
  payload.forEach((c) => {
    if (coupons[c.type]) {
      coupons[c.type]++;
    } else {
      coupons[c.type] = 1;
    }
  });

  // check if other is the only coupon with 1 and add a key to the object
  if (coupons.other === 1 && Object.keys(coupons).length === 1) {
    coupons.onlyDelivery = true;
  }

  return coupons;
}

function formattedSamplePrice(amount) {
  const cost = amount * 0.75;

  return amount === 1 ? '75p' : `£${cost.toFixed(2)}`;
}

function createKey(type, length) {
  let text = '';
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < length; i++) {
    text += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return type + '-' + text;
}

function transformImage(image, config) {
  return `${image}?tr=${config}`;
}

export default {
  getMpId,
  stringHasher,
  colorChanger,
  setStorage,
  getStorage,
  getCatColor,
  skuToProduct,
  formatFoodTypeName,
  foodSuggestor,
  setUrlParameter,
  getSubscriptionItems,
  calcDiscount,
  encodeString,
  decodeString,
  setAdID,
  getPercentageOf,
  getPercentageDiff,
  getCookie,
  isNewProduct,
  foodTypes,
  getDisplayCost,
  sendAdEvent,
  formatCoupons,
  formattedSamplePrice,
  createKey,
  transformImage
};
