<template>
  <div
    class="dialog-content default-animation fixed inset-0 z-40 overflow-y-auto bg-gray-800/60"
    id="modal-overflow"
  >
    <transition
      enter-active-class="miniFadeInTop"
      leave-active-class="miniFadeOutBottom"
      v-on:after-enter="afterEnter"
      v-on:after-leave="afterLeave"
    >
      <div
        class="pin flex items-center justify-center px-4 py-16"
        v-if="showContent"
        @click="modalProps.outerClose ? handleClose : null"
      >
        <div
          class="animated dialog-content z-50 grid w-full rounded-lg bg-white shadow-lg sm:w-1/2 lg:w-1/3"
          :class="{ 'modal-wrapper': !isFull, 'modal-extend': isFull }"
          @click.stop
        >
          <transition name="fade" mode="in-out">
            <slot></slot>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Modal',
  props: {
    redirect: {
      type: String
    }
  },
  data() {
    return {
      showContent: false,
      loadContent: false
    };
  },
  computed: {
    ...mapState({
      modalTitle: 'modalTitle',
      modalProps: 'modalProps'
    }),
    isFull() {
      return this.modalProps?.isFull ? this.modalProps?.isFull : false;
    }
  },

  mounted() {
    setTimeout(
      function () {
        this.showContent = true;
      }.bind(this),
      400
    );

    document.addEventListener('keydown', (e) => {
      if (this.showContent && e.keyCode == 27) {
        this.handleClose();
      }
    });
    this.$on('make-close', function (redirect) {
      this.handleClose(redirect);
    });
  },
  methods: {
    ...mapMutations(['hide_modal']),
    afterEnter: function () {
      this.loadContent = true;
    },
    afterLeave: function () {
      this.hide_modal();
    },

    handleClose: function (redirect) {
      this.showContent = false;
      this.loadContent = false;
      if (redirect) {
        this.$router.push({ name: redirect });
      }
    }
  }
};
</script>
<style lang="scss">
.backdrop-blur {
  backdrop-filter: blur(3px);
}
.dialog-content {
  z-index: 999999999999999;
}
.modal-wrapper {
  max-width: 420px;
}

.modal-extend {
  width: auto !important;
}
</style>
