const campaigns = [
  {
    id: 'CCCG1707221',
    name: 'Grain Free - NOV21',
    heroTitle: 'Grain-Free cat food, delivered to your door',
    heroDescription: [
      'We produce our own wet & dry food, specifically formulated to be vet approved grain free food. Treat your cat to a healthy diet. Start with a Taster Box crammed full of our food...'
    ],
    heroSubtext:
      'Read on to find out why grain free is the sensible choice for your cat',
    sections: [
      {
        title: 'Why Grain-Free?',
        description: [
          'With feline nutrition it’s best to go back to basics. Cats are pure carnivores, it’s crucial they have a meat based diet without carbohydrates. In the wild, feline’s don’t look for or eat any carbs so adding these in can only cause issues. Sadly many commercial brands in supermarkets add carbohydrates and grains to bulk out the food (as they are far cheaper to use than real meat).',
          'As cats digestive systems have simply never been designed or evolved to digest grains, it can potentially put their sensitive stomachs under strain every time they eat food containing grain.',
          'As well as discomfort, this can sometimes be shown in loose faecal matter or their outward behaviour / happiness. In other words, to ensure you have a happy and healthy cat, a grain free diet is the sensible choice!'
        ]
      },
      {
        title: 'Grain-Free wet & dry food made easy',
        description: [
          'At The Cool Cat Club, all our food is specially developed by us to be Grain Free. For your added peace of mind, our wet food is made with 100% natural ingredients and has no added sugar.',
          'Whether you choose wet or dry or both, you can receive it via a handy monthly delivery so you never run out. It also means you can rest knowing your cat is receiving healthy food everyday without the hassle and issues searching for new food! We offer complete flexibility, so you can easily adjust or cancel your monthly order anytime.'
        ]
      }
    ],
    review: {
      name: 'Jo Sayer',
      review:
        'Very impressed with the service from Cool Cat Club. My order was delivered the next day and included a cute note for our new kitten Motsi. I looked around for a grain free and high meat content food (without having to pay a fortune) and was very pleased to find the Cool Cat Club. Our 8 week old kitten loves the food now that she has settled in, maybe a little too much! 😊',
      title: 'Purrfect'
    },
    ctaText:
      'Treat your cat to a Taster Box packed full of our 10 different grain free food flavours'
  },
  {
    id: 'CCCG5268221',
    name: 'Sensitive Cat food - NOV21',
    heroTitle: 'Sensitive cat food, delivered to your door.',
    heroDescription: [
      'Wet & dry food specifically developed for Sensitive Cats. Try a Taster Box crammed full of our food.'
    ],
    heroSubtext:
      'Read on to find out why we make food with sensitive cats in mind...',
    sections: [
      {
        title: 'What causes cats to be sensitive?',
        description: [
          'To begin, it`s important to understand, cats little stomachs are already quite sensitive. Anything that isn’t natural could easily cause an upset or discomfort. Many commercial brands and supermarket brands are unfortunately filled out with sugar and cheap carbs.',
          'They also tend to use the generic term ‘meat derivatives’ which means they can use all different kinds of animal meat. So for example `Meat derivatives (with Chicken 4%)`, means  only 4% contains chicken and the rest could be whatever meat is cheap at the abattoir at the time! This meat inconsistency can often lead to problems, particularly with more sensitive cats.'
        ]
      },
      {
        title: 'The Solution',
        description: [
          'Try to stick to as natural a diet as possible and always ensure the food is Grain Free and has No Added Sugar. It can also be good to seek out mono-protein food, otherwise known as single protein food. This means the protein or meat is from one source which limits the chances of sensitivity. The less meat sources usually correlates with less sensitivity, so try to choose just one (e.g. Chicken or Tuna) or two combined (e.g. Cod & Salmon)',
          'Where possible, it’s always best to avoid changing their food too often as this can lead to issues with their sensitive stomachs. This is why a subscription can make sense, ensuring you receive a convenient regular delivery and you never run out.',
          'There are potential medical reasons why your cat may be sensitive such as allergies to certain ingredients, which is best to consult your vet about.'
        ]
      },
      {
        title: 'Try Our Food',
        description: [
          'At The Cool Cat Club, all our wet & dry food is Grain Free and has No Added Sugar. We’ve also developed several mono-protein flavours such as our Chicken and our Tuna pouches and recyclable trays. Our dry food has also been created for sensitive cats and is hypoallergenic..'
        ]
      }
    ],
    review: {
      name: 'Jill Philips',
      review:
        'Fabulous customer service! One of my cats suffers from a sensitive tummy and a lot of cat foods set him off. The taster pack went down such a great with no problems. Both took to the New food right away and so my subscription was started with Cool Cat Club without hesitation. Two very happy furry customers :)',
      title: 'Best cat food out there'
    },
    ctaText:
      'Treat your cat to a Taster Box crammed full of our wet & dry food.'
  },
  {
    id: 'CCCG3196221',
    name: 'Fussy Cat food - NOV21',
    heroTitle: 'Healthy Cat Food, loved by Fussy Cats.',
    heroDescription: [
      'We’ve developed our wet & dry food specifically for the fussiest of cats and kittens. Try a Taster Box crammed full of our 10 tasty varieties.'
    ],
    heroSubtext:
      'Read on to see why we make our food specifically for fussy cats...',
    sections: [
      {
        title: 'What causes a cat to be fussy?',
        description: [
          'Unlike dogs, cats are naturally more selective with their food. They can like a food for a while and then go off it, which can be a natural process yet very frustrating!',
          'Often the reason a cat or kitten is fussy, isn’t purely ‘cats being cats’...it’s the ingredients in the food. Many commercial brands use Meat derivatives which allows them to use different kinds of animal meat which can change in every batch! So for example ‘Meat derivatives (with Chicken 4%), means only 4% contains chicken and the rest could be whatever meat is cheap at the abattoir at the time! Your cat isn’t stupid and can pick up on the changes in taste and flavour and thus go off this food.',
          'The recipes can also affect the taste. Many manufacturers use non-natural ingredients like sugar or syrup and cheap carbs because they are cheap. Recipes that stick as close to nature are often the ones that perform best with fussy cats.'
        ]
      },
      {
        title: 'The Solution',
        description: [
          'To limit the chances of your cat being fussy it’s wise to feed a healthy diet which has a clearly labelled meat source on the ingredients (e.g. 50% Chicken), rather than the generic ‘meat derivatives’.',
          'It can also be prudent to give them a wide variety of flavours as possible so they don’t get used to one flavour or texture of food.',
          'There are potential medical reasons why your cat may be sensitive such as allergies to certain ingredients, which is best to consult your vet about.'
        ]
      },
      {
        title: 'Try with your cat',
        description: [
          'At The Cool Cat Club, we’ve developed our food recipes especially with fussy cats in mind. We’ve combined different flavours and ingredients and done 100’s of taste tests to understand what even the fussiest of cats like! Our food is as close to nature as possible, our ingredients are clearly marked and all our wet and dry food is Grain Free with No Added Sugar.',
          'The food is made available via a handy monthly subscription so you can get peace of mind you’re feeding healthy food every day and never run out. Because we have 12 different types of wet and dry food you can give them plenty of variety and easily adjust their flavours anytime!'
        ]
      }
    ],
    review: {
      name: 'Nicola',
      review:
        'My cat is very fussy with her food and I was having to try different ones often. I decided to get the trial box from the cool cat club. My cat loves it so much and will not eat anything else. After subscribing to the monthly box I received it the next day. It`s very quick and easy to arrange for the next box to come earlier if you happen to be running low before the next box is due. Great food and great service.',
      title: 'My cat is very fussy with her food'
    },
    ctaText:
      'Try a Taster pack packed full of our 10 most popular flavours of wet and dry food.'
  },
  {
    id: 'CCCG7172221',
    name: 'Blink Compare - NOV21',
    heroTitle: 'The Main Alternative To Blink Cat Food',
    heroDescription: [
      'The Cool Cat Club offers premium cat and kitten food for less than Blink. In fact, you could save between 17% - 30% every single month.'
    ],
    ctaText:
      'Join 1,000’s of savvy cat owners who have already made the switch. ',
    isCompare: true,
    compareName: 'Blink',
    compareStats: [
      { amount: '30', price: 2892, cccPrice: 2199 },
      { amount: '60', price: 5357, cccPrice: 3999 },
      { amount: '90', price: 7821, cccPrice: 5199 }
    ],
    review: {
      name: 'Nicola',
      review:
        'My cat is very fussy with her food and I was having to try different ones often. I decided to get the trial box from the cool cat club. My cat loves it so much and will not eat anything else. After subscribing to the monthly box I received it the next day. It`s very quick and easy to arrange for the next box to come earlier if you happen to be running low before the next box is due. Great food and great service.',
      title: 'My cat is very fussy with her food'
    }
  },
  {
    id: 'CCCG7903022',
    name: 'Blink Kitten Compare - JAN21',
    heroTitle: 'The Main Alternative To Blink Kitten Food',
    heroDescription: [
      'The Cool Cat Club offers premium kitten food for less than Blink. In fact, you could save between 17% - 30% every single month.'
    ],
    ctaText:
      'Join 1,000’s of savvy cat owners who have already made the switch. ',
    isCompare: true,
    compareName: 'Blink',
    compareStats: [
      { amount: '30', price: 2892, cccPrice: 2199 },
      { amount: '60', price: 5357, cccPrice: 3999 },
      { amount: '90', price: 7821, cccPrice: 5199 }
    ],
    review: {
      name: 'Nicola',
      review:
        'My cat is very fussy with her food and I was having to try different ones often. I decided to get the trial box from the cool cat club. My cat loves it so much and will not eat anything else. After subscribing to the monthly box I received it the next day. It`s very quick and easy to arrange for the next box to come earlier if you happen to be running low before the next box is due. Great food and great service.',
      title: 'My cat is very fussy with her food'
    }
  },
  {
    id: 'CCCG4442922',
    isSlim: true,
    name: 'Katkin Specific Landing Page',
    heroTitle: 'Premium Food. No Defrosting Required',
    heroDescription: [
      'Frozen food, such as Katkins, is good food. By its nature, it requires defrosting before serving. For cat owners who want a simpler option or just don’t have the freezer space, we offer an alternative option.',
      'Our premium food is a different type of texture, requires no preparation and can be stored in a cupboard. It can also be up to 30% cheaper.'
    ],
    heroPretext: 'Get healthy food delivered to your door. Try first!',

    links: [
      { label: 'Our Food', link: 'the food' },
      { label: 'Pricing', link: 'pricing' }
    ],
    review: {
      name: 'Jo Sayer',
      review:
        'Very impressed with the service from Cool Cat Club. My order was delivered the next day and included a cute note for our new kitten Motsi. I looked around for a grain free and high meat content food (without having to pay a fortune) and was very pleased to find the Cool Cat Club. Our 8 week old kitten loves the food now that she has settled in, maybe a little too much! 😊',
      title: 'Purrfect'
    },
    ctaText:
      'Treat your cat to a Taster Box packed full of our 10 different grain free food flavours'
  }
];

export default campaigns;
