import Vue from 'vue';

import http from '@/http';
import utils from '@/utils';
import mixpanel from 'mixpanel-browser';
import campaigns from '@/views/landing/campaigns.js';
import { filter, orderBy, isEmpty } from 'lodash/fp';

const stateObj = {
  status: '',
  token: localStorage.getItem('token') || '',
  user: {},
  isSignup: localStorage.getItem('signup') ? true : false,
  trackingDisabled: localStorage.getItem('trkDb') ? true : false,
  modalVisible: false,
  modalComponent: null,
  modalTitle: '',
  modalData: '',
  modalProps: undefined,
  modalRedirect: null,
  updateSubscription: false,
  mpId: '',
  gaClientId: '',
  samplePrice: '',
  basket: '',
  maxWet: 120,
  maxDry: 4000,
  subValidation: {
    isValid: true,
    message: '',
    isWetMax: false,
    isDryMax: false
  },
  subscription: null,
  ctaText: 'Start your 1st Month',
  ctaTextTaster: 'Try a Taster Box!',
  showChat: true,
  isTaster: !isEmpty(utils.getStorage('checkout', 'local')) || false,
  isTasterType: 'adult',
  tasterPrice: '£5',
  tasterValue: 5.0,
  discountAmount: null,
  isFirstMonth: true,
  currentColor: utils.colorChanger()[0],
  orderHistory: null,
  latestOrder: null,
  campaigns: campaigns,
  paymentMethods: null,
  corePrices: [],
  deliveryOptions: {},
  selectedDeliveryOption: null
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, token) {
    state.status = 'success';
    state.token = token;
  },
  auth_error(state) {
    state.status = 'error';
  },
  user_success(state, user) {
    state.user = user;
  },
  update_tracking(state, tracking) {
    state.trackingDisabled = tracking;
  },
  update_subscription(state, payload) {
    state.updateSubscription = payload;
  },
  show_modal(state, payload) {
    state.modalVisible = true;
    state.modalComponent = payload.component;
    state.modalTitle = payload.title;
    state.modalData = payload.data;
    state.modalProps = { ...payload?.props, outerClose: payload?.outerClose };
    state.modalRedirect = payload.redirect;
  },
  update_modal(state, payload) {
    state.modalTitle = payload.title;
  },
  hide_modal(state) {
    state.modalVisible = false;
    state.modalRedirect = null;
    state.modalProps = undefined;
  },
  set_mp_id(state, payload) {
    state.mpId = payload;
  },
  set_ga_client_id(state, payload) {
    state.gaClientId = payload;
  },
  set_sample_price(state, payload) {
    state.samplePrice = payload;
  },

  logout(state) {
    state.status = '';
    state.token = '';
    state.user = '';
  },
  update_minimum_sub(state, payload) {
    state.subValidation = payload;
  },
  set_subscription(state, payload) {
    state.subscription = payload;
  },
  set_show_chat(state, payload) {
    state.showChat = !payload;
  },
  set_as_taster(state, payload) {
    state.isTaster = payload;
    utils.setStorage('checkout', payload, 'local');
  },
  set_taster_type(state, payload) {
    state.isTasterType = payload;
  },
  set_taster_price(state, payload) {
    state.tasterPrice = payload;
  },
  set_taster_copy(state, payload) {
    state.ctaTextTaster = 'Try a Taster Box for ' + payload + '!';
  },
  set_taster_value(state, payload) {
    state.tasterValue = payload;
  },
  set_discount_amount(state, payload) {
    state.discountAmount = payload;
    state.discountAmount.isValid = payload.cost > 0;
  },
  set_current_color(state, payload) {
    state.currentColor = payload;
  },
  set_payment_methods(state, payload) {
    state.paymentMethods = payload;
  },
  set_order_history(state, payload) {
    state.orderHistory = payload;
    state.latestOrder = orderBy(['createdAt'], ['desc'], payload)[0];
  },
  clear_storage(state, payload) {
    payload.forEach((store) => {
      localStorage.removeItem(store);
    });
  },
  set_core_price(state, payload) {
    state.corePrices.push({ amount: payload.amount, value: payload.value });
  },
  set_delivery_options(state, payload) {
    Vue.set(state.deliveryOptions, payload.type, payload.options);
  },
  set_delivery_option(state, payload) {
    state.selectedDeliveryOption = payload;
  },
  update_samples(state, payload) {
    state.subscription.bonusFood = payload;
  },
  update_cat_details(state, payload) {
    console.log(payload);
    // find the cat in the subscription items
    const cat = state.subscription.items.find((cat) => cat.id === payload.id);

    cat[payload.key] = payload.value;
  }
};

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      http
        .post('/login', user)
        .then((resp) => {
          // const token = resp.data.token;
          const token = 'Bearer ' + resp.data.content;
          localStorage.setItem('token', token);
          http.defaults.headers.common['Authorization'] = token;
          commit('auth_success', token);
          resolve(resp);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },
  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      http
        .post('/createaccount', user)
        .then((resp) => {
          const token = 'Bearer ' + resp.data.content;
          localStorage.setItem('token', token);
          http.defaults.headers.common['Authorization'] = token;
          commit('auth_success', token);
          resolve(resp);
        })
        .catch((err) => {
          commit('auth_error', err);
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/me')
        .then((response) => {
          const user = response.data.content[0];
          commit('user_success', user);

          resolve(user);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  updateCatDetails(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put('/cat_update', { items: payload })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  setUser({ commit }, user) {
    commit('user_success', user);
    sessionStorage.setItem('user', JSON.stringify(user));
  },
  disableTracking({ commit }, tracking) {
    commit('update_tracking', tracking);
    localStorage.setItem('trkDb', tracking);
  },
  getSamplePrice({ commit }) {
    let samplePrice;
    http
      .get('/samplebox')
      .then((response) => {
        samplePrice = response.data.content[0].displayCost;
        samplePrice = samplePrice.replace('£', '');
        localStorage.setItem('samplePrice', samplePrice);
        commit('set_sample_price', samplePrice);
      })
      .catch(() => {
        throw Error("Couldn't get sample pack price");
      });
  },

  getSubscription({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (getters.isLoggedIn) {
        http
          .get('/subscription')
          .then((response) => {
            const subscription = response.data.content[0];
            subscription.links = {
              adjust: response.data.links.updateSubscriptionProduct.replace(
                '/api',
                ''
              ),
              cancel: response.data.links.deleteSubscriptionProduct.replace(
                '/api',
                ''
              )
            };

            commit('set_subscription', subscription);

            resolve(subscription);
          })
          .catch((err) => {
            reject(err);
            throw Error(err.response.data.error.message);
          });
      } else {
        resolve(false);
      }
    });
  },
  getTasterPrice({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/samplebox')
        .then((response) => {
          commit('set_taster_price', response.data.content[0].displayCostShort);
          commit('set_taster_copy', response.data.content[0].displayCostShort);
          commit('set_taster_value', response.data.content[0].cost / 100);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getDiscountAmount({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/trialdiscount')
        .then((response) => {
          commit('set_discount_amount', response.data.content[0]);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getOrderHistory({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/me/orderhistory')
        .then((response) => {
          commit('set_order_history', response.data.content);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getPaymentMethods({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get('/me/paymentmethods')
        .then((response) => {
          commit('set_payment_methods', response.data.content);

          resolve(response);
        })
        .catch((err) => {
          reject(err);
          throw Error(err.response.data.error.message);
        });
    });
  },
  getSubscriptionCost(_, payload) {
    return new Promise((resolve, reject) => {
      const data = {
        cats: []
      };

      payload.forEach((c) => {
        const products = c.products;

        products.forEach((p) => {
          delete p.getPrice;
        }),
          data.cats.push({ products: products });
      });

      http
        .post('/subscription_product_preview', data)
        .then((response) => {
          resolve(response.data.content[0]);
          this.subscriptionCost = response.data.content[0];
        })
        .catch((err) => {
          reject();
          throw Error(err.response.data.error.message);
        });
    });
  },
  addAddress({ dispatch, state }, address) {
    return new Promise((resolve, reject) => {
      if (address.address2 === null) {
        address.address2 = '';
      }

      address.postcode = address.postcode.toUpperCase();

      http
        .put('/me/address', address)
        .then((response) => {
          let user = state.user;

          user.shippingAddress = response.data.content[0];
          user.phone = address.phone;

          dispatch('setUser', user);
          resolve(response);
        })
        .catch((error) => {
          console.log('error', error);
          reject(error);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('token');
      sessionStorage.removeItem('user');
      localStorage.removeItem('cats');
      localStorage.removeItem('plans');
      commit('set_payment_methods', null);
      delete http.defaults.headers.common['Authorization'];
      resolve();
    });
  },
  sendMpEvent(_, payload) {
    mixpanel.track(payload.event, payload.properties);
  },
  sendFbEvent(_, payload) {
    if (window.fbq) {
      if (payload.eventId) {
        window.fbq(payload.type, payload.name, payload.data, {
          eventID: payload.eventId
        });
      } else {
        window.fbq(payload.type, payload.name, payload.data);
      }
    }
  },
  removeCat(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/cat/${payload}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  addPayday(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(`/subscripton/payday`, { dom: payload.dom })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removePayday() {
    return new Promise((resolve, reject) => {
      http
        .delete(`/subscription/payday`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  sendToNotion(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(`/notion_update`, { db: payload.db, content: payload.data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  getCorePrices({ commit }, payload) {
    payload.forEach((p) => {
      const data = {
        cats: [{ products: [{ sku: 'W_PCH_CHK', quantity: p / 5 }] }]
      };

      http
        .post('/subscription_product_preview', data)
        .then((response) => {
          commit('set_core_price', {
            amount: p,
            value: response.data.content[0].intTotal
          });
        })
        .catch((err) => {
          throw Error(err);
        });
    });
  },
  getDeliveryOptions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      http
        .get(`/deliveryoptions`, { params: payload.params })

        .then((response) => {
          commit('set_delivery_options', {
            type: payload.params.type,
            options: response.data.content
          });
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  orderHistory: (state) => state.orderHistory,
  latestOrder: (state) => state.latestOrder,

  authStatus: (state) => state.status,
  subValidation: (state) => state.subValidation,
  getCurrentSubscription: (state) => state.subscription,
  getChatStatus: (state) => state.showChat,
  getIsTaster: (state) => state.isTaster,
  getTasterType: (state) => state.isTasterType,
  getTasterPrice: (state) => state.tasterPrice,
  getTasterValue: (state) => state.tasterValue,
  getCurrentUser: (state) => state.user,
  getDiscountAmount: (state) => state.discountAmount,
  isFirstMonth: (state) => state.isFirstMonth,
  currentColorGetter: (state) => state.currentColor,
  getSamplePrice: (state) => state.samplePrice,
  getUnavailableProducts: (state) =>
    filter(['available', false], state.products),
  getCampaigns: (state) => state.campaigns,
  getPaymentMethodsGetter: (state) => state.paymentMethods,
  corePricesGetter: (state) => state.corePrices,
  deliveryOptionsGetter: (state) => state.deliveryOptions,
  activeDeliveryOptionGetter: (state) => state.selectedDeliveryOption,
  modalVisibleGetter: (state) => state.modalVisible,
  modalComponentGetter: (state) => state.modalComponent,
  modalTitleGetter: (state) => state.modalTitle,
  modalDataGetter: (state) => state.modalData,
  modalPropsGetter: (state) => state.modalProps,
  modalRedirectGetter: (state) => state.modalRedirect
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
