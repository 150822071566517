<template>
  <div
    @click="setTaster(ctaTextTaster, true)"
    class="has-blue inline-flex w-full cursor-pointer items-center justify-center rounded bg-yellow-200 text-center font-semibold hover:bg-yellow-200 sm:w-auto"
    :class="isSmall ? 'h-10 px-4 text-base' : 'h-16 px-6 text-lg'"
  >
    <div>{{ ctaTextTaster }}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    tasterType: {
      type: String,
      required: false,
      default: 'adult'
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    section: {
      type: String
    }
  },
  computed: {
    ...mapState({
      ctaText: (state) => state.core.ctaText,
      ctaTextTaster: (state) => state.core.ctaTextTaster
    })
  },
  components: {},
  methods: {
    ...mapActions({
      sendEvent: 'sendMpEvent'
    }),
    setTaster(label, isTaster) {
      this.sendEvent({
        event: this.ctaTextTaster,
        properties: {
          location: this.$route.name,
          section: this.section,
          type: 'EVT'
        }
      });
      this.$store.commit('set_as_taster', isTaster);
      this.$store.commit('set_taster_type', this.tasterType);
      this.$store.dispatch('sendFbEvent', {
        type: 'trackCustom',
        name: 'GetTasterBox',
        data: {}
      });
      this.$router.push({
        name: 'cats',
        params: {
          isTaster: true,
          tasterType: this.tasterType
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.has-blue {
  color: #0b315e;
}
.outline-btn {
  border-color: rgba(11, 49, 94, 0.8);
  &:hover {
    color: #0b315e;
    background: rgba(11, 49, 94, 0.1);
  }
}

.w-128 {
  width: 22rem;
}
.mini-rotate {
  transform: rotate(-3deg);
}
.svg-fill {
  @apply fill-current;
}
</style>
