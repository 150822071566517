import http from '@/http';

const stateObj = {};

const mutations = {};
const actions = {
  createTicket(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .post(`/freshdesk`, payload)
        .then(resp => {
          resolve(resp.data.content);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
