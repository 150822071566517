import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '@/filters';

Vue.config.productionTip = false;

import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY, {
  debug: process.env.NODE_ENV !== 'production'
});

import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faInstagram,
  faFacebookF
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheck,
  faCoins,
  faArrowRightLong,
  faArrowLeftLong,
  faChevronDown,
  faChevronUp,
  faTimes as farTimes,
  faCircleInfo,
  faBars,
  faTrashCanXmark,
  faEye,
  faEyeSlash,
  faSliders,
  faPlus,
  faBan,
  faCircleExclamation,
  faMinus,
  faStars,
  faBoxOpen,
  faRuler,
  faBalanceScaleLeft,
  faBookOpen,
  faSirenOn
} from '@fortawesome/pro-regular-svg-icons';
import {
  faTurkey,
  faFish,
  faDrumstick,
  faCalendarDay,
  faCalendarWeek,
  faBellOn,
  faLayerPlus,
  faTruck,
  faHouseUser,
  faThumbsUp,
  faThumbsDown,
  faQuestionCircle,
  faWeight,
  faDice,
  faBoxOpen as falBoxOpen,
  faCheck as falCheck,
  faBoxCircleCheck,
  faCircleXmark,
  faTriangleExclamation,
  faTicketSimple,
  faCamera,
  faSparkles,
  faXmark
} from '@fortawesome/pro-light-svg-icons';
import {
  faBoxCheck,
  faBoxOpen as fadBoxOpen,
  faTruckRampBox
} from '@fortawesome/pro-duotone-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faTwitter,
  faCoins,
  faInstagram,
  faFacebookF,
  faCheck,
  faArrowRightLong,
  faArrowLeftLong,
  faBellOn,
  faChevronDown,
  faChevronUp,
  faTurkey,
  faFish,
  faMinus,
  faDrumstick,
  faTrashCanXmark,
  faCircleInfo,
  faBars,
  faEye,
  faEyeSlash,
  faTruck,
  faSliders,
  faPlus,
  faBan,
  faThumbsUp,
  faThumbsDown,
  faCircleExclamation,
  faCalendarDay,
  faStar,
  faStars,
  faCalendarWeek,
  faBoxOpen,
  fadBoxOpen,
  faLayerPlus,
  faQuestionCircle,
  faRuler,
  faBalanceScaleLeft,
  faWeight,
  faDice,
  faBookOpen,
  faSirenOn,
  faHouseUser,
  faBoxCheck,
  falBoxOpen,
  falCheck,
  faTruckRampBox,
  falBoxOpen,
  faBoxCircleCheck,
  faCircleXmark,
  faTriangleExclamation,
  faTicketSimple,
  faCamera,
  faSparkles,
  faXmark
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
