<template>
  <div class="relative flex h-10 w-10 items-center rounded-full bg-teal-400">
    <div
      class="z-10 w-full text-center font-sans text-sm font-semibold uppercase tracking-widest text-white"
    >
      {{ initials }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["username"],
  computed: {
    initials: function () {
      return this.username.match(/\b(\w)/g).join("");
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-bg {
  @apply fill-current;
}
</style>
