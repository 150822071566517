import http from '@/http';

const stateObj = {};

const mutations = {};
const actions = {
  uploadImage(_, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload);
      http
        .put(`/cat/${payload.id}/image`, payload.data, payload.config)
        .then((resp) => {
          resolve(resp.data.content);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  removeImage(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/cat/${payload.id}/image`)
        .then((resp) => {
          resolve(resp.data.content);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
