const forwards = [
  {
    id: 'T10752',
    name: 'Trustpilot - QR Code',
    link: 'https://uk.trustpilot.com/evaluate/thecoolcatclub.com'
  },
  {
    id: 'R10886',
    name: 'Review Email',
    link: 'https://uk.trustpilot.com/evaluate/thecoolcatclub.com'
  },
  {
    id: 'C15331',
    name: 'Choice Flyer',
    link: 'https://thecoolcatclub.com/food?id=food-selection-start'
  }
];

export default forwards;
