<template>
  <div>
    <!-- <div v-if="showCommunity" class=" z-50 relative block">
      <reviews :review-type="reviewMeta" />
    </div> -->

    <div class="bg-gray-100 p-4 py-8  text-sm">
      <div class="container mx-auto">
        <!-- <four-paws-overview v-if="showCommunity" class="" /> -->

        <div class="grid grid-cols-2 gap-y-4 sm:grid-cols-6">
          <div class=" col-span-1 ">
            <div class=" font-medium  text-gray-700">
              About Us
            </div>
            <div class="flex flex-col text-gray-600 mt-2">
              <router-link :to="{ name: 'about us' }">About Us</router-link>
              <router-link :to="{ name: 'kitten' }">Kittens</router-link>
              <router-link :to="{ name: 'the food' }">Our Food</router-link>

              <a
                target="_blank"
                rel="noopener"
                href="https://blog.thecoolcatclub.com"
                >Blog</a
              >
            </div>
          </div>
          <div class=" col-span-1 sm:col-span-2">
            <div class=" font-medium  text-gray-700">
              Our Services
            </div>
            <div class="flex flex-col text-gray-600 mt-2 ">
              <router-link :to="{ name: 'faqs' }">FAQs</router-link>
              <router-link :to="{ name: 'terms conditions' }"
                >Terms &amp; Conditions</router-link
              >
              <router-link :to="{ name: 'privacy policy' }"
                >Privacy Policy</router-link
              >
            </div>
          </div>
          <div class="w-full col-span-2">
            <div class="font-medium  text-gray-700">
              Contact Us
            </div>
            <div class="flex flex-col text-gray-600 mt-2">
              <div class="text-gray-500">
                Feedback is our catnip. Send us some at...
              </div>
              <a href="mailto:team@thecoolcatclub.com"
                >team@thecoolcatclub.com</a
              >

              <!-- <div class="mt-1 flex text-lg">
                <div class="p-4 pl-0">
                  <font-awesome-icon :icon="['fab', 'twitter']" />
                </div>
                <div class="p-4">
                  <font-awesome-icon :icon="['fab', 'instagram']" />
                </div>
                <div class="p-4">
                  <font-awesome-icon :icon="['fab', 'facebook-f']" />
                </div>
              </div> -->
            </div>
          </div>

          <div class="flex col-span-1 sm:justify-end">
            <div class="w-16 sm:w-20">
              <logo :color="logoColor" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';

// import FourPawsOverview from '@/components/FourPawsOverview';

export default {
  props: ['color'],
  data() {
    return {
      logoColor: '#a0aec0',
      isLoggedIn: this.$store.getters.isLoggedIn,
      showCommunity: this.$route.meta.showCommunity,
      reviewMeta: this.$route.meta.reviewType
    };
  },
  watch: {
    $route(to) {
      this.showCommunity = to.meta.showCommunity;
      this.reviewMeta = this.$route.meta.reviewType;
    }
  },
  components: {
    Logo
    // FourPawsOverview
  }
};
</script>

<style lang="css" scoped></style>
