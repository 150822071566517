import Vue from 'vue';
import Vuex from 'vuex';

import samples from './modules/samples.js';
import core from './modules/core.js';
import freshdesk from './modules/freshdesk.js';
import tasters from './modules/tasters.js';
import images from './modules/images.js';
import products from './modules/products.js';
import recommendations from './modules/recommendations.js';
Vue.use(Vuex);
export default new Vuex.Store({
  namespaced: true,
  modules: {
    core,
    samples,
    freshdesk,
    tasters,
    images,
    products,
    recommendations
  }
});
