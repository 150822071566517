import http from '@/http';

const stateObj = {};

const mutations = {};
const actions = {
  hideRecommendation(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .delete(`/cat/${payload.id}/recommendations`, { data: payload.data })
        .then((resp) => {
          resolve(resp.data.content);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

const getters = {};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
