<template>
  <div>
    <img
      alt="The Cool Cats Club Logo"
      class="hidden"
      src="images/cool-cat-logo-dark.webp"
    />
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 142.5 105.8"
      style="enable-background:new 0 0 142.5 105.8;"
      xml:space="preserve"
    >
      <g>
        <polygon
          :style="fillStyle"
          points="19.2,6.8 24.1,6.6 24.1,27.4 19.7,27.7 19.7,29.8 34,29.8 34,27.7 29.2,27.4 29.2,6.6 34,6.8
    		35.3,14.3 38,14.3 37.6,4.4 15.6,4.4 15,14.3 17.9,14.3 	"
        />
        <path
          :style="fillStyle"
          d="M41.9,27.5L40,27.7v2.1h8.9v-2.1l-2.1-0.3V15c0.6-0.5,1.2-0.8,2-1.2c0.7-0.3,1.5-0.5,2.2-0.5
    		c0.8,0,1.4,0.2,1.9,0.5c0.4,0.3,0.7,0.8,0.9,1.5c0.2,0.7,0.2,1.6,0.2,2.8v9.4l-2,0.3v2.1h9v-2.1l-2.2-0.3V18c0-1.8-0.2-3.3-0.5-4.4
    		c-0.3-1.1-0.9-1.9-1.7-2.4c-0.8-0.5-1.9-0.8-3.4-0.8c-1.3,0-2.6,0.3-3.8,0.8c-1.2,0.5-2.2,1.1-2.9,1.8l0.2-3l0-7.6l-0.9-0.6h-0.1
    		l-6.4,0.8v1.8l2.6,0.4V27.5z"
        />
        <path
          :style="fillStyle"
          d="M76.1,29.3c1.4-0.6,2.4-1.2,3-1.8l-0.9-1.9c-0.5,0.4-1.3,0.8-2.2,1.1c-0.9,0.3-1.9,0.4-2.8,0.4
    		c-1.6,0-2.9-0.6-3.8-1.7s-1.4-2.8-1.5-4.8h11.1c0.2-0.7,0.3-1.8,0.3-3.4c0-2.2-0.7-3.9-1.9-5.1c-1.2-1.2-3-1.8-5.3-1.8
    		c-1.8,0-3.4,0.4-4.8,1.2c-1.4,0.8-2.5,1.9-3.2,3.5c-0.8,1.5-1.1,3.3-1.1,5.3c0,3.1,0.7,5.5,2.2,7.2c1.5,1.8,3.7,2.7,6.7,2.7
    		C73.3,30.2,74.7,29.9,76.1,29.3z M69,13.8c0.6-0.9,1.5-1.3,2.6-1.3c2,0,3,1.5,3,4.5c0,0.3,0,0.8-0.1,1.5h-6.6
    		C68,16.3,68.3,14.7,69,13.8z"
        />
        <path
          :style="fillStyle"
          d="M47.7,93.4c-0.4,0.4-0.9,0.6-1.4,0.8c-0.6,0.2-1.4,0.3-2.4,0.3c-2.1,0-3.8-1-4.9-3.1c-1.1-2.1-1.7-4.8-1.7-8
    		c0-3.4,0.6-6,1.8-7.8s2.7-2.7,4.7-2.7c1,0,1.7,0.1,2.4,0.2s1,0.4,1.3,0.6l1.1,4.9h3.1l0.4-7c-0.1,0-0.6-0.1-1.6-0.2
    		c-1.3-0.2-2.4-0.4-3.3-0.5c-0.9-0.1-1.9-0.2-2.9-0.2c-2.4,0-4.5,0.5-6.4,1.6c-1.9,1.1-3.4,2.6-4.5,4.6c-1.1,2-1.6,4.3-1.6,6.9
    		c0,4,1,7.2,3.1,9.5c2.1,2.3,5,3.5,8.9,3.5c1.1,0,2.1-0.1,2.9-0.2c0.8-0.1,1.6-0.3,2.5-0.6c0.1,0,0.4-0.1,0.9-0.2
    		c0.5-0.1,0.9-0.2,1.4-0.3l0.7-5.3l-2.9-0.3L47.7,93.4z"
        />
        <polygon
          :style="fillStyle"
          points="61.5,69 60.6,68.4 60.5,68.4 54.2,69.1 54.2,71 56.7,71.4 56.7,94.1 54.3,94.4 54.3,96.4 64,96.4
    		64,94.4 61.5,94.1 	"
        />
        <path
          :style="fillStyle"
          d="M83.8,77.5L83,77H83l-6.2,0.4v1.9l2.3,0.5v12.6c-1.2,1-2.4,1.6-3.8,1.6c-1.1,0-1.9-0.3-2.3-1
    		c-0.4-0.7-0.6-1.8-0.6-3.5v-12L71.7,77h0l-5.8,0.4v2l1.7,0.5v9.6c0,2.6,0.5,4.5,1.4,5.6c0.9,1.1,2.5,1.7,4.7,1.7
    		c1.1,0,2.2-0.3,3.4-0.9c1.1-0.6,2-1.2,2.6-2v0.9c0,0.6,0.2,1.1,0.5,1.4s0.9,0.5,1.6,0.5c1,0,1.9-0.1,2.7-0.2
    		c0.8-0.2,1.2-0.3,1.3-0.3l0-1.9h-1.9V77.5z"
        />
        <path
          :style="fillStyle"
          d="M99.1,76.9c-1,0-2,0.2-2.9,0.6c-0.9,0.4-1.7,0.9-2.3,1.6V69l-1-0.6h-0.1l-6.3,0.8v1.8l2.6,0.4v24.2
    		c0.4,0.3,1.2,0.5,2.5,0.8c1.3,0.3,2.9,0.5,4.7,0.5c2,0,3.8-0.5,5.4-1.4c1.5-0.9,2.7-2.2,3.5-3.8c0.8-1.6,1.2-3.4,1.2-5.3
    		c0-3.2-0.7-5.5-2.1-7.1C103.1,77.7,101.3,76.9,99.1,76.9z M100.1,92.7c-0.9,1.4-2,2-3.5,2c-0.6,0-1.2-0.1-1.7-0.2
    		c-0.5-0.2-0.8-0.4-1-0.6V81.4c0.4-0.4,0.8-0.7,1.5-1c0.6-0.3,1.2-0.5,1.9-0.5c1.2,0,2.2,0.6,3,1.7c0.8,1.1,1.1,2.8,1.1,5.1
    		C101.4,89.4,101,91.4,100.1,92.7z"
        />
        <path
          :style="fillStyle"
          d="M113,91.2c-0.9,0-1.6,0.3-2.2,0.8c-0.6,0.5-0.9,1.2-0.9,2.1c0,0.8,0.3,1.4,0.8,1.9c0.5,0.5,1.1,0.7,1.8,0.7
    		c0.9,0,1.7-0.3,2.3-0.8c0.6-0.5,0.9-1.2,0.9-2.1c0-0.7-0.2-1.3-0.7-1.8S113.8,91.2,113,91.2z"
        />
        <path
          :style="fillStyle"
          d="M17,59.6c-0.4,0.4-0.9,0.6-1.4,0.8c-0.6,0.2-1.4,0.3-2.4,0.3c-2.1,0-3.8-1-4.9-3.1c-1.1-2.1-1.7-4.8-1.7-8
    		c0-3.4,0.6-6,1.8-7.8s2.7-2.7,4.7-2.7c1,0,1.7,0.1,2.4,0.2s1,0.4,1.3,0.6l1.1,4.9h3.1l0.4-7c-0.1,0-0.6-0.1-1.6-0.2
    		c-1.3-0.2-2.4-0.4-3.3-0.5c-0.9-0.1-1.9-0.2-2.9-0.2c-2.4,0-4.5,0.5-6.4,1.6c-1.9,1.1-3.4,2.6-4.5,4.6C1.5,45,1,47.3,1,50
    		c0,4,1,7.2,3.1,9.5c2.1,2.3,5,3.5,8.9,3.5c1.1,0,2.1-0.1,2.9-0.2c0.8-0.1,1.6-0.3,2.5-0.6c0.1,0,0.4-0.1,0.9-0.2
    		c0.5-0.1,0.9-0.2,1.4-0.3l0.7-5.3l-2.9-0.3L17,59.6z"
        />
        <path
          :style="fillStyle"
          d="M37.7,61.8c1.4-0.7,2.6-1.9,3.4-3.4c0.9-1.5,1.3-3.4,1.3-5.5c0-3-0.8-5.4-2.3-7.2c-1.6-1.8-3.8-2.7-6.7-2.7
    		c-1.7,0-3.2,0.4-4.7,1.1c-1.4,0.8-2.6,1.9-3.4,3.4S24,50.9,24,53.1c0,3,0.8,5.4,2.4,7.2c1.6,1.8,3.8,2.7,6.6,2.7
    		C34.7,62.9,36.3,62.6,37.7,61.8z M30.2,58.7c-0.6-1.4-1-3.3-1-5.9c0-5,1.3-7.5,4-7.5c1.4,0,2.4,0.7,3,2c0.6,1.4,0.9,3.3,0.9,5.9
    		c0,5-1.3,7.5-3.9,7.5C31.9,60.7,30.9,60,30.2,58.7z"
        />
        <path
          :style="fillStyle"
          d="M50.3,44.2c-1.4,0.8-2.6,1.9-3.4,3.4s-1.3,3.3-1.3,5.5c0,3,0.8,5.4,2.4,7.2c1.6,1.8,3.8,2.7,6.6,2.7
    		c1.7,0,3.3-0.4,4.7-1.1c1.4-0.7,2.6-1.9,3.4-3.4c0.9-1.5,1.3-3.4,1.3-5.5c0-3-0.8-5.4-2.3-7.2c-1.6-1.8-3.8-2.7-6.7-2.7
    		C53.3,43.1,51.7,43.4,50.3,44.2z M57.8,47.3c0.6,1.4,0.9,3.3,0.9,5.9c0,5-1.3,7.5-3.9,7.5c-1.4,0-2.4-0.7-3.1-2
    		c-0.6-1.4-1-3.3-1-5.9c0-5,1.3-7.5,4-7.5C56.1,45.3,57.1,46,57.8,47.3z"
        />
        <polygon
          :style="fillStyle"
          points="66.7,60.5 66.7,62.6 76.3,62.6 76.3,60.5 73.8,60.2 73.8,35.2 72.9,34.5 72.8,34.5 66.5,35.3
    		66.5,37.1 69,37.6 69,60.2 	"
        />
        <path
          :style="fillStyle"
          d="M102.3,59.6c-0.4,0.4-0.9,0.6-1.4,0.8c-0.6,0.2-1.4,0.3-2.4,0.3c-2.1,0-3.8-1-4.9-3.1c-1.1-2.1-1.7-4.8-1.7-8
    		c0-3.4,0.6-6,1.8-7.8c1.2-1.8,2.7-2.7,4.7-2.7c1,0,1.7,0.1,2.4,0.2c0.6,0.2,1,0.4,1.3,0.6l1.1,4.9h3.1l0.4-7
    		c-0.1,0-0.6-0.1-1.6-0.2c-1.3-0.2-2.4-0.4-3.3-0.5c-0.9-0.1-1.9-0.2-2.9-0.2c-2.4,0-4.5,0.5-6.4,1.6c-1.9,1.1-3.4,2.6-4.5,4.6
    		c-1.1,2-1.6,4.3-1.6,6.9c0,4,1,7.2,3.1,9.5c2.1,2.3,5,3.5,8.9,3.5c1.1,0,2.1-0.1,2.9-0.2c0.8-0.1,1.6-0.3,2.5-0.6
    		c0.1,0,0.4-0.1,0.9-0.2c0.5-0.1,0.9-0.2,1.4-0.3l0.7-5.3l-2.9-0.3L102.3,59.6z"
        />
        <path
          :style="fillStyle"
          d="M124.7,50c0-1.7-0.2-3-0.6-4c-0.4-1-1.1-1.7-2.1-2.2c-1-0.5-2.4-0.7-4.1-0.7c-1.2,0-2.3,0.2-3.5,0.5
    		c-1.1,0.3-2.1,0.7-2.9,1.1c-0.8,0.4-1.4,0.8-1.7,1.1l1.1,2.2c1-0.5,1.9-0.8,2.8-1.1s1.9-0.4,3-0.4c1,0,1.7,0.1,2.2,0.4
    		c0.5,0.3,0.9,0.7,1.1,1.2c0.2,0.5,0.3,1.3,0.3,2.2v0.8c-3.3,0-6,0.6-8.1,1.6c-2.1,1-3.1,2.6-3.1,4.7c0,1.2,0.3,2.2,0.8,3.1
    		c0.5,0.9,1.2,1.5,2.1,1.9c0.9,0.4,1.8,0.7,2.9,0.7c1.2,0,2.3-0.3,3.3-0.8c1-0.5,1.8-1.2,2.4-1.8v0.9c0,0.7,0.1,1.2,0.4,1.4
    		s0.8,0.3,1.6,0.3c0.6,0,1.4-0.1,2.2-0.2c0.8-0.1,1.5-0.2,1.9-0.3v-1.9h-1.8V50z M120.1,58.6c-0.5,0.4-1,0.8-1.6,1s-1.2,0.4-1.6,0.4
    		c-0.9,0-1.6-0.3-2.1-0.9c-0.5-0.6-0.8-1.4-0.8-2.5c0-1.1,0.5-2,1.5-2.7c1-0.7,2.5-1,4.6-1V58.6z"
        />
        <path
          :style="fillStyle"
          d="M140.2,59.1c-0.4,0.2-0.9,0.3-1.5,0.5s-1.2,0.2-1.8,0.2c-0.6,0-1-0.1-1.2-0.2s-0.4-0.5-0.4-1s-0.1-1.4-0.1-2.7
    		v-9.6h5.2v-2.6h-5.2l-0.1-5h-2.7c-0.5,1.9-0.9,3.1-1.1,3.6c-0.3,0.7-0.6,1.2-1,1.5c-0.3,0.1-0.6,0.3-1.1,0.4s-0.8,0.3-1,0.4v1.8
    		h2.2v12.6c0,1.4,0.3,2.5,1,3.1c0.7,0.7,1.7,1,3.2,1c1.2,0,2.4-0.2,3.6-0.6c1.2-0.4,2.2-0.8,2.8-1.3L140.2,59.1z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['color'],
  computed: {
    fillStyle: function() {
      return {
        fill: this.color
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
