import http from '@/http';

const stateObj = {
  availableSamples: []
};

const mutations = {
  set_available_samples(state, payload) {
    state.availableSamples = payload;
  }
};
const actions = {
  addSample(_, payload) {
    return new Promise((resolve, reject) => {
      http
        .put(`/freesamples`, { samples: payload })
        .then((resp) => {
          resolve(resp.data.content);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSamples({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get(`/freesamples`)
        .then((resp) => {
          commit('set_available_samples', resp.data.content);
          resolve(resp.data.content);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

const getters = {
  currentSamplesGetter(state, getters, rootState) {
    return rootState.core?.subscription?.bonusFood;
  },
  availableSamplesGetter(state, getters, rootState) {
    return rootState.samples?.availableSamples;
  }
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
