import Vue from 'vue';
import { format, formatDistanceToNow } from 'date-fns';
import { startCase } from 'lodash';

Vue.filter('numberFormat', function (value) {
  return new Intl.NumberFormat().format(value);
});

Vue.filter('formatPounds', function (value) {
  const pounds = value / 100;
  return `£${pounds.toLocaleString(undefined, {
    minmumSignificantDigits: 2,
    minimumFractionDigits: 2
  })}`;
});

Vue.filter('startCase', function (value) {
  return startCase(value);
});

Vue.filter('dryFormat', function (value) {
  if (value < 1000) {
    return value + 'g';
  }
  return value / 1000 + 'kg';
});
Vue.filter('timeago', function (value) {
  return formatDistanceToNow(new Date(value), { addSuffix: true });
});
Vue.filter('formatDate', function (value, dateFormat) {
  const date = new Date(value);

  if (dateFormat) {
    return format(date, dateFormat);
  } else {
    return format(date, 'dd/MM/yyyy hh:mmaaa');
  }
});

Vue.filter('unencodeURL', function (value) {
  return decodeURIComponent(value);
});
