import http from '@/http';
import { camelCase } from 'lodash/fp';
const stateObj = {
  tasterBoxCosts: false,
  tasterBoxes: [
    {
      name: 'Mixed',
      key: 'original',
      costKey: 'adult_dry_original',
      type: 'adult',
      description: {
        dry: ['5 different Chicken recipes', '5 different Fish recipes'],
        noDry: ['4 different Chicken recipes', '5 different Fish recipes']
      },
      active: true
    },
    {
      name: 'Chicken',
      key: 'chicken',
      costKey: 'adult_nodry_chicken',
      type: 'adult',
      description: {
        dry: ['9 different Chicken recipes'],
        noDry: ['8 different Chicken recipes']
      },
      active: false
    },
    {
      name: 'Fish',
      key: 'fish',
      costKey: 'adult_nodry_fish',
      type: 'adult',

      description: {
        dry: ['9 different Fish recipes'],
        noDry: ['8 different Fish recipes']
      },
      active: false
    },
    {
      name: 'Chicken + Fish Bundle',
      key: 'both',
      costKey: 'adult_nodry_both',
      discount: 1000,
      popular: true,
      type: 'adult',

      description: {
        dry: [
          '9 different Chicken recipes',
          '9 different Fish recipes',
          'Try more & save with our most popular option!'
        ],
        noDry: [
          '8 different Chicken recipes',
          '8 different Fish recipes',
          'Try more  & save with our most popular option!'
        ]
      },
      active: false
    },
    {
      name: 'Kitten',
      key: 'original',
      costKey: 'adult_dry_original',
      type: 'kitten',
      description: {
        dry: [
          '4 different Kitten Chicken recipes',
          '4 different Kitten Fish recipes'
        ],
        noDry: [
          '4 different Kitten Chicken recipes',
          '4 different Kitten Fish recipes'
        ]
      },
      active: true
    },
    {
      name: 'None',
      key: 'none',
      costKey: '',
      type: 'adult',
      active: true
    },
    {
      name: 'None',
      key: 'none',
      costKey: '',
      type: 'kitten',
      active: true
    }
  ]
};

const mutations = {
  set_tasters_cost(state, payload) {
    state.tasterBoxCosts = payload;
  },
  update_tasters(state, payload) {
    state.tasterBoxes = state.tasterBoxes.map((taster) => {
      const costKey = payload[camelCase(taster.costKey)];

      const cost = costKey;
      return {
        ...taster,
        cost
      };
    });
    state.tasterBoxCosts = true;
  }
};
const actions = {
  getTasterBoxesCost({ commit }) {
    return new Promise((resolve, reject) => {
      http
        .get(`/samplebox/cost`)
        .then((resp) => {
          commit('set_tasters_cost', resp.data.content);
          resolve(resp.data.content[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};

const getters = {
  availableTasters: (state) =>
    state.tasterBoxes.filter((taster) => taster.active),
  hasTastersCosts: (state) => state.tasterBoxCosts
};

export default {
  state: stateObj,
  mutations,
  actions,
  getters
};
