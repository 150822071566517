<template>
  <div :class="`${transition} ${currentColor} ${sticky}`">
    <div class="top-0 z-20 w-full" :class="{ 'is-home relative px-1': isHome }">
      <div
        class="default-transition container mx-auto py-4"
        :class="{ 'py-4': isHome }"
      >
        <nav class="bg-teal flex flex-wrap items-center justify-between">
          <div
            class="flex-no-shrink flex items-center justify-center text-white"
          >
            <router-link :to="{ name: isLoggedIn ? 'dashboard' : 'home' }">
              <div class="default-transition ml-1 w-16 sm:w-20">
                <logo :color="logoColor" />
              </div>
            </router-link>
          </div>

          <div
            v-if="isLoggedIn"
            class="mr-0 flex flex-grow items-center justify-between font-medium sm:mr-4"
          >
            <div class="nav-links"></div>
            <router-link class="flex items-center" :to="{ name: 'settings' }">
              <div class="nav-link hidden sm:block">
                <div class="mr-4">Settings</div>
              </div>

              <transition
                name="test"
                enter-active-class="animated bounceIn"
                leave-active-class="animated "
              >
                <avatar
                  :username="user.name"
                  v-if="user.name"
                  class="default-animation" /></transition
            ></router-link>
          </div>
          <div class="flex items-center">
            <router-link
              v-if="!isLoggedIn"
              class="nav-link mr-4 no-underline sm:hidden"
              :to="{ name: 'login' }"
              >Login</router-link
            >
            <div class="block p-6 pr-2 text-2xl sm:hidden sm:p-0">
              <button
                @click="toggle"
                class="nav-link flex items-center hover:border-white hover:text-white"
              >
                <font-awesome-icon :icon="['far', 'bars']" />
              </button>
            </div>
          </div>
          <div
            :class="{ block: open, hidden: !open, 'flex-grow': !isLoggedIn }"
            class="w-full font-medium sm:flex sm:w-auto sm:items-center"
          >
            <div
              class="sm:justify nav-links w-full sm:flex"
              :class="{ 'justify-end': isLoggedIn }"
            >
              <div v-if="isLoggedIn" class="flex flex-col" @click="toggle">
                <div class="nav-links">
                  <router-link
                    v-if="isLoggedIn"
                    class="mt-4 block text-center no-underline sm:ml-4 sm:mr-4 sm:mt-0 sm:inline-block sm:hidden"
                    :to="{ name: 'dashboard' }"
                    >Dashboard</router-link
                  >
                  <router-link
                    class="mt-4 block text-center no-underline sm:ml-4 sm:mr-4 sm:mt-0 sm:inline-block sm:hidden"
                    :to="{ name: 'settings' }"
                  >
                    Settings
                  </router-link>

                  <button
                    class="is-logout w-full cursor-pointer py-4 text-center sm:py-0 sm:text-left"
                    @click="logout"
                  >
                    <a> Logout</a>
                  </button>
                </div>
              </div>
              <div
                class="sm:flex sm:w-full sm:items-center sm:justify-between"
                v-else
              >
                <div class="flex-1 p-2" @click="toggle">
                  <router-link
                    class="mt-4 block no-underline sm:ml-4 sm:mr-4 sm:mt-0 sm:inline-block"
                    :to="{ name: 'the food' }"
                    >Our Food</router-link
                  >
                  <router-link
                    class="mt-4 block no-underline sm:mr-4 sm:mt-0 sm:inline-block"
                    :to="{ name: 'pricing' }"
                    >Pricing</router-link
                  >

                  <router-link
                    class="mt-4 block no-underline sm:mr-4 sm:mt-0 sm:inline-block"
                    :to="{ name: 'kitten' }"
                    >Kittens</router-link
                  >
                  <router-link
                    class="mt-4 block no-underline sm:mr-4 sm:mt-0 sm:inline-block"
                    :to="{ name: 'faqs' }"
                    >FAQs</router-link
                  >
                </div>
                <div
                  class="flex flex-col items-center space-x-4 p-2 sm:flex-row"
                  @click="toggle"
                >
                  <action-ctas
                    class="w-full sm:flex sm:justify-center"
                    section="navigation"
                    is-small
                  />

                  <router-link
                    class="mt-4 block no-underline sm:mr-4 sm:mt-0 sm:inline-block"
                    :to="{ name: 'login' }"
                    >Login</router-link
                  >
                </div>
              </div>

              <!-- <a
              href="#responsive-header"
              class="no-underline block mt-4 sm:inline-block sm:mt-0 text-white hover:text-white mr-4"
            > -->
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';
import Avatar from '@/components/Avatar';
import ActionCtas from '@/components/ActionCtas';
import store from '@/store';
import utils from '@/utils';
import { mapState } from 'vuex';
export default {
  props: ['color'],
  components: {
    Logo,
    Avatar,
    ActionCtas
  },
  data() {
    return {
      open: false,
      logoColor: '#000',
      isHome: true,
      utils,
      currentColor: this.color,
      transition: 'color-transition'
    };
  },
  watch: {
    $route(to) {
      this.setStyle(to.name);
    },
    color: function (newValue) {
      if (this.isHome) {
        this.currentColor = newValue;
      }
    }
  },
  computed: {
    isLoggedIn: function () {
      return store.getters.isLoggedIn;
    },
    user: function () {
      return this.$store.state.core.user;
    },
    sticky: function () {
      return this.isHome ? 'relative' : '';
    },
    ...mapState({
      ctaTextTaster: 'ctaTextTaster'
    })
  },
  created() {
    this.setStyle(this.$route.name);
  },
  methods: {
    toggle: function () {
      this.open = !this.open;
    },
    showModal: function (title, component, data) {
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data
      });
    },

    setStyle: function () {
      let self = this;
      if (this.$route.name === 'home' || this.$route.name === 'landing') {
        this.isHome = true;
        this.logoColor = '#0b315e';
        this.currentColor = this.color;
        setTimeout(function () {
          self.transition = `color-transition`;
        }, 100);
      } else {
        this.isHome = false;
        this.currentColor = 'bg-white';
        this.transition = '';
        this.logoColor = '#0b315e';
      }
    },

    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.toggle();

        this.$store.dispatch('sendMpEvent', {
          event: 'Logged Out',
          properties: {
            location: this.$route.name,
            type: 'EVT'
          }
        });
        this.$store.commit('set_mp_id', this.utils.getMpId());
        this.$router.push('/');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.is-home {
  position: relative !important;
}

.is-home .nav-links a {
  color: #0b315e;
}

.is-home .nav-links a.is-hero {
  border-radius: 9999px;
  background-color: #f6e05e;
  padding: 8px 16px;
}

.nav-links a {
  color: #0b315e;
  padding-left: 8px;
  padding-right: 8px;
}

.nav-links a.is-hero {
  border-radius: 9999px;
  background-color: #f6e05e;
  padding: 8px 16px;
}

.nav-link {
  color: #0b315e;
}
</style>
